import HttpRequest from './core/http_request';

class PublicProvider extends HttpRequest {
  getStatus () {
    return this.request('get', '/status');
  }

  getFullCalendar (data) {
    return this.request('get', '/public/getFullCalendar', data);
  }

  getCountEvent (data) {
    return this.request('get', '/public/getCalendarEvent', data);
  }

  getmemberByOrganizationID (data) {
    return this.request('get', '/public/getmemberlistByOrganizationID', data);
  }

  getConfigHighlightByPage (data) {
    return this.request('get', '/public/getHighlight/' + data.name, data);
  }

  getExternalIframe (data) {
    return this.request('get', '/public/getExternalIframe/' + data._id);
  }

  getLocalContent (data) {
    return this.request('get', '/public/getLocalContent', data);
  }

  getLocalContentByID (data) {
    return this.request('get', '/public/getLocalContent/' + data._id);
  }

  getPartner (data) {
    return this.request('get', '/public/getPartner' , data);
  }

  getPublicRegionList (data) {
    return this.request('get', '/public/getPublicRegionList' , data);
  }

  getCategory (data) {
    return this.request('get', '/public/getCategory' , data);
  }

  getEditorchoiceByPage (data) {
    return this.request('get', '/public/getEditorChoice', data);
  }
}

export default PublicProvider;
