import AuthProvider from './auth_service';
import MemberProvider from './member_service';
import PublicProvider from './public_service';
import MasterProvider from './master_service';
import LocalcontentProvider from './localcontent_service';


export const authService = new AuthProvider('auth');
export const memberService = new MemberProvider('member');
export const publicService = new PublicProvider('public');
export const masterService = new MasterProvider('master');
export const localcontentService = new LocalcontentProvider('content');

