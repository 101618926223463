/* global localStorage */

import { authService } from '../services';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, user }
  : { loggedIn: false, user: null };

const authModule = {
  namespaced: true,
  state: {
    initialState
  },
  mutations: {
    loginSuccess  (state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    loginFailure  (state) {
      state.loggedIn = false;
      state.user = null;
    }
  },
  actions: {
    async loginMember ({ commit }, data) {
      try {
        const result = await authService.loginMember(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async register ({ commit }, data) {
      try {
        const result = await authService.register(data);
        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async loginByCsite ({ commit }, data) {
      try {
        const result = await authService.loginByCsite(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async loginWithSocial ({ commit }, data) {
      try {
        const result = await authService.loginWithSocial(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async registerWithSocial ({ commit }, data) {
      try {
        const result = await authService.registerWithSocial(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async emailVerification ({ commit }, data) {
      try {
        const result = await authService.emailVerification(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async updatePassword ({ commit }, data) {
      try {
        const result = await authService.updatePassword(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async requestForgetPassword ({ commit }, data) {
      try {
        const result = await authService.requestForgetPassword(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async verifyForgetPasswordToken ({ commit }, data) {
      try {
        const result = await authService.verifyForgetPasswordToken(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async resetPassword ({ commit }, data) {
      try {
        const result = await authService.resetPassword(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
  },
  getters: {
    getLoginState (state) {
      return state.loggedIn;
    },
    getUser (state) {
      return state.user;
    }
  }
};

export default authModule;
