import { memberService, publicService, masterService, localcontentService } from '../services'; // , surveyService, adminService, masterService

const apiModule = {
  namespaced: true,
  state: {
    memberService: memberService,
    publicService: publicService,
    masterService: masterService,
    localcontentService: localcontentService


    // surveyService: surveyService,
    // adminService: adminService,
  },
  actions: {
    async apiCall ({ commit }, data) {
      const result = await this.state.api[data.svName][data.fnName](data.param);
      return result;
    }
  }
};

export default apiModule;
