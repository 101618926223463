import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },{
        path: '/login2',
        name: 'login2',
        component: () => import(/* webpackChunkName: "auth-login2" */ '../views/auth/login2.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth-register" */ '../views/auth/register.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/pass-recovery',
        name: 'pass-recovery',
        component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/pass_recovery.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/forgotpassword/:token',
        name: 'forgotpassword',
        props: true,
        component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/forgotPassword.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard.vue'), 
    },
    {
        path: '/local',
        name: 'local',
        component: () => import(/* webpackChunkName: "local" */ '../views/map/local.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/north',
        name: 'north',
        component: () => import(/* webpackChunkName: "north" */ '../views/map/north.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/northeast',
        name: 'northeast',
        component: () => import(/* webpackChunkName: "northeast" */ '../views/map/northeast.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/bkk',
        name: 'bkk',
        component: () => import(/* webpackChunkName: "bkk" */ '../views/map/bkk.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/central',
        name: 'central',
        component: () => import(/* webpackChunkName: "central" */ '../views/map/central.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/south',
        name: 'south',
        component: () => import(/* webpackChunkName: "south" */ '../views/map/south.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/template',
        name: 'template',
        props: true,
        component: () => import(/* webpackChunkName: "template" */ '../views/content/template.vue'), 
        meta: { layout: 'auth', authorize: 'member', permission: 'author' }
    },
    {
        path: '/addLocalcontent/:templateno/:id',
        name: 'addLocalcontent',
        props: true,
        component: () => import(/* webpackChunkName: "addLocalcontent" */ '../views/content/addLocalcontent.vue'), 
        meta: { layout: 'auth', authorize: 'member', permission: 'author' },
    },
    {
        path: '/myLocalcontent',
        name: 'myLocalcontent',
        props: true,
        component: () => import(/* webpackChunkName: "myLocalcontent" */ '../views/content/myLocalcontent.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/humanelephant',
        name: 'humanelephant',
        props: true,
        component: () => import(/* webpackChunkName: "humanelephant" */ '../views/longform/central/humanelephant.vue'), 
        meta: { layout: 'auth' },
    },
    
    // and finally the default route, when none of the above matches:
    {   
        path: '/:pathMatch(.*)*',
        // name: 'pagenotfound',
        component: () => import(/* webpackChunkName: "pagenotfound" */ '../views/pagenotfound.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/externalIframe/:id',
        name: 'externalIframe',
        props: true,
        component: () => import(/* webpackChunkName: "externalIframe" */ '../views/content/externalIframe.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/localcontent/:id',
        name: 'localcontent',
        props: true,
        component: () => import(/* webpackChunkName: "localcontent" */ '../views/content/localContent.vue'), 
        meta: { layout: 'auth' }
    },
    {
        path: '/contentlist/:region',
        name: 'contentlist',
        component: () => import(/* webpackChunkName: "localcontent" */ '../views/contentlist/local.vue'), 
        props: true,
        meta: { layout: 'auth' }
    },
    {
        path: '/mapdemo',
        name: 'mapdemo',
        component: () => import(/* webpackChunkName: "mapdemo" */ '../views/map/mapDemo.vue'), 
        meta: { layout: 'auth' },
    },
    {
        path: '/contentlistdemo',
        name: 'contentlistdemo',
        component: () => import(/* webpackChunkName: "contentlistdemo" */ '../views/contentlist/demo.vue'), 
        meta: { layout: 'auth' }
    },
    {
        path: '/localdemo',
        name: 'localdemo',
        component: () => import(/* webpackChunkName: "localdemo" */ '../views/map/demo/localDemo.vue'), 
        meta: { layout: 'auth' },
    },
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('member');
    const memberData = JSON.parse(loggedIn);
    const token = localStorage.getItem('token');
    const isAuthor = (memberData) ? memberData.isAuthor : false;
    const { authorize, permission } = to.meta;

    if (authorize) {
        if (!loggedIn && !token) {
            next('/login');
        } else {
            if (permission && permission === 'author' && !isAuthor) {
                next('/');
            }
        }
    }

    if (to.path === '/') {
        return next('/local');
    }

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    next(true);
});

export default router;
