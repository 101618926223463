import HttpRequest from './core/http_request';

class LocalcontentProvider extends HttpRequest {

  uploadContentFile (data) {
    return this.request('upload', '/uploadFile/uploadLocalcontentFile', data);
  }

  addLocalContent (data) {
    return this.request('post', '/localContent', data);
  }

  updateLocalContentByID (data) {
    const id = data.id;
    delete data.id;
    return this.request('post', '/localContent/id/' + id, data);
  }

  getMyLocalContentList (data) {
    return this.request('get', '/localContent/getMyLocalContent', data);
  }

  getLocalContentByID (data) {
    return this.request('get', '/localContent/id/' + data._id, data);
  }

  deleteLocalContent (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/localContent/delete/' + id, data);
  }

  getCategory (data) {
    return this.request('get', '/public/getCategory', data);
  }


}

export default LocalcontentProvider;
