import HttpRequest from './core/http_request';

class MemberProvider extends HttpRequest {
  getStatus () {
    return this.request('get', '/status');
  }

  myAccount (data) {
    return this.request('get', '/member/myaccount');
  }

  updateProfile (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/member/myaccount', data);
  }

  uploadMemberFile (data) {
    return this.request('upload', '/uploadFile/uploadMemberFile', data);
  }

  addEventCalendar (data) {
    return this.request('post', '/eventsCalendar', data);
  }
}

export default MemberProvider;
